<template>
  <app-page
    :hideBottomBar="user ? false : true"
    container
    :title="$vuetify.lang.t('$vuetify.privacy_policy_title')"
  >
    <EnglishPrivacy v-if="lang == 'en'" />
    <DutchPrivacy v-else-if="lang == 'nl'" />
    <FrenchPrivacy v-else />
  </app-page>
</template>

<script>
import EnglishPrivacy from "./english/privacy-policy.vue";
import DutchPrivacy from "./dutch/privacy-policy.vue";
import FrenchPrivacy from "./french/privacy-policy.vue";
import { createNamespacedHelpers } from "vuex";
const user_module = createNamespacedHelpers("user");
export default {
  name: "privacy-policy",
  components: {
    EnglishPrivacy,
    DutchPrivacy,
    FrenchPrivacy,
  },
  data() {
    return {
      lang: this.$vuetify.lang.current,
    };
  },
  computed: {
    ...user_module.mapState(["user"]),
  },
};
</script>
